@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.MembersOverview {
  padding: 0;
  background: none;
}

.DivisionAssignmentOverviewRow:first-child td {
  border-top: none;
}

.drop-zone {
  position: relative;
  padding: $su-medium;
  cursor: pointer;
  border-bottom: 1px solid $cu-divider--light;
  background: $cu-middleground--light;
  &.active {
    .drop-info {
      z-index: 100;
    }
    &:after {
      content: '';
      background-color: $cu-middleground;
    }
  }
  td {
    background: none;
  }
  .pre-drop,
  .post-drop {
    // temp
    display: none;
  }
  &:hover {
    .drop-info {
      color: $cu-primary;
    }
  }
}

.drop-info {
  height: $su-xlarge;
  border: 1px dashed scaleColor($cu-primary, 4);
  border-radius: 5px;
  z-index: 10;
  text-align: center;
  line-height: $su-xlarge;
  font-size: $tu-small-fontSize;
  color: $cu-info--light;
}

.OverviewCount {
  display: flex;
  width: 100%;
  height: 60px;
  padding-top: $su-medium;
  padding-bottom: $su-medium;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $cu-divider--light;
}

.OverviewCount-item {
  flex: 1 1 25%;
  border-right: 1px solid $cu-divider--light;
  padding-left: $su-medium;
  &:last-child {
    border-right: none;
  }
}

.OverviewCount-title {
  color: $cu-info--light;
  margin-bottom: 0;
  font-size: $tu-small-fontSize;
  font-weight: 400;
}

.OverviewCount-count {
  color: $cu-info;
  font-size: 18px;
}

.active-tab {
  border-bottom: 3px solid #1364de !important;
  color: #1364de !important;
}
