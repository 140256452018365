@import '~@teamsnap/teamsnap-ui/src/css/config/index';

// TODO: clean this up with flexbox

.wrapper {
  width: 100%;
  margin-right: 1.5%;
}

.month {
  width: 47%;
  margin-right: 1.5%;
}

.day {
  width: 22.5%;
  margin-right: 1.5%;
}

.year {
  width: 27.5%;
  margin-right: 0;
}
