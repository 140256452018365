@import '~@teamsnap/teamsnap-ui/src/css/config/index';

//------------------------------------------------------------//

// * Getting Started Widget
// * 1. SideNav Styles
// * 2. Content Styles

// Notes:

//------------------------------------------------------------//

// * 1. SideNav Styles

.SideNav {
  border-right: 1px solid $ts-grey;
  padding-right: 0 !important;
  position: relative;
}

.SideNav-header,
.SideNav-step {
  border-bottom: 1px solid $cu-divider--light;
}

.SideNav-title {
  color: $ts-darkblue;
}

.SideNav-note {
  color: $cu-info--light;
  font-size: $tu-small-fontSize;
}

.SideNav-step {
  $height: scaleGrid(7);
  list-style: none;
  border-bottom: 1px solid $cu-divider--light;
  position: relative;
  height: $height;
  line-height: $height;
  padding: 0;

  &:before,
  &:after {
    content: '';
    display: none;
    position: absolute;
    right: (0 - $su-medium);
    top: 0;
    width: 0;
    height: 0;
    border-top: ($height / 2) solid transparent;
    border-bottom: ($height / 2) solid transparent;
    border-left: $su-medium solid $cu-foreground;
  }

  &:before {
    border-top: ($height / 2) solid transparent;
    border-bottom: ($height / 2) solid transparent;
    border-left: $su-medium solid $cu-divider;
    right: (-1 - $su-medium);
  }

  &:hover {
    background: $cu-middleground;
    &:after {
      border-left-color: $cu-middleground;
    }
  }
}

.SideNav-footer {
  height: scaleGrid(7);
  line-height: scaleGrid(7);
  padding-left: $su-medium;
}

.SideNav-tour {
  color: $cu-highlight;
  cursor: pointer;
  &:hover {
    color: scaleColor($cu-highlight, -1);
  }
}

// NOTE: when moving to TeamSnap-UI this should be a nested js-active class
.SideNavStepActive {
  &:before,
  &:after {
    display: block;
  }
}

.SideNavstepLink {
  display: block;
  padding-left: scaleGrid(5);
  &,
  &:hover {
    color: $cu-info--light;
  }
}

.SideNavstepLinkActive {
  @extend .SideNavstepLink;
  font-weight: $tu-semibold-fontWeight;
  &,
  &:hover {
    color: $cu-info;
  }
}

.SideNavstepLinkIcon {
  width: $su-medium;
  height: $su-medium;
  line-height: $su-medium;
  text-align: center;
  border-radius: 50%;
  background: $cu-background;
  border: 1px solid $ts-grey;
  box-shadow: $inset-box-shadow-small;
  position: absolute;
  top: 0;
  bottom: 0;
  left: $su-medium;
  margin: auto;
}

.SideNavstepLinkIconCompleted {
  @extend .SideNavstepLinkIcon;
  background: $cu-positive;
  color: $ts-white;
  font-size: $su-small;
  box-shadow: none;
  border: none;
}

// * 2. Content Styles

.Step {
  position: relative;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: scaleGrid(30) center;
  border-top-right-radius: $border-radius-large;
  border-bottom-right-radius: $border-radius-large;
}

.Step-title {
  padding-bottom: $su-large;
  font-size: $tu-xlarge-fontSize;
  font-family: $tu-openSans-fontFamily;
  font-weight: $tu-semibold-fontWeight;
  color: $cu-info;
}

.Step-details {
  padding-bottom: $su-xlarge;
  max-width: scaleGrid(35);
  p {
    padding-bottom: $su-medium;
    &:last-child {
      padding-bottom: 0;
    }
  }
}

.Logo {
  max-width: 33%;
}
