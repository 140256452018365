// Select
//  -> Select box component styles
//
// Select - default 100% width
// SelectInline - modifier to allow label inline
//
// ============================================================

@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.Select {
  composes: SelectBox from '~@teamsnap/teamsnap-ui/src/css/components/SelectBox.scss';
  width: 100%;
}

.SelectAligned {
  composes: Select;
  flex: 1 1 0%;
  flex-basis: 0%;
}

.SelectIsDisabled {
  composes: SelectBox is-disabled from '~@teamsnap/teamsnap-ui/src/css/components/SelectBox.scss';
}

.SelectOptions {
  composes: SelectBox-options from '~@teamsnap/teamsnap-ui/src/css/components/SelectBox.scss';
  @media (max-width: $breakpoint-sm-max) {
    font-size: $tu-large-fontSize;
  }
}

.SelectText {
  composes: SelectBox-text from '~@teamsnap/teamsnap-ui/src/css/components/SelectBox.scss';
}

.SelectError {
  composes: Select;
  border-color: $cu-negative !important;
}

.SelectAlignedError {
  composes: SelectAligned;
  border-color: $cu-negative !important;
}
