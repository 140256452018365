@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.clubEmailAddedAttachments {
  overflow: hidden;
  width: 100%;
  margin-bottom: 0;
  td {
    border-left: none !important;
    border-right: none !important;
  }
  tr:first-child td {
    border-top: none !important;
  }
}
