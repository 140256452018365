@import '~@teamsnap/teamsnap-ui/src/css/config/index';

// TODO: turn count into a component
// TODO: ceate a small or tight table modifier

.divisionListRow:first-child td {
  border-top: none;
}

.divisionListItem {
  padding: $su-small $su-small;
  font-size: $tu-small-fontSize;
  a {
    line-height: $su-large;
  }
}

.indicator--ss-navigateright {
  font-size: 80%;
  display: inline-block;
}

.count {
  background: scaleColor($cu-divider, 6);
  padding: 0 $su-small;
  display: inline-block;
  margin-left: $su-xsmall;
  height: $su-large;
  line-height: $su-large;
  border-radius: ($su-large / 2);
  font-size: $tu-small-fontSize;
  font-weight: $tu-base-fontWeight;
  i {
    display: inline-block;
    padding-left: 2px;
  }
}
