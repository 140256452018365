@import '~@teamsnap/teamsnap-ui/src/css/config/index';

// TODO: look at turning the legend into a component

.grid--1-5 {
  width: 50%;
  padding-right: $su-medium;
}

.hrs__l-roster-legend {
  p {
    background: #fff;
  }
  margin-top: 12px;
}

.legend {
  position: relative;
  text-align: center;
  padding: $su-small 0;
  &:before {
    content: '';
    height: 1px;
    background: $cu-divider;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
  }
}

.legend-title,
.legend-items {
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 10;
  display: inline-block;
  font-size: $tu-base-fontSize;
  height: 20px;
  line-height: 20px;
  background: $cu-foreground;
  list-style: none;
}

.legend-title,
.legend-item {
  padding: 0 $su-medium;
  border-right: 1px solid $cu-divider;
  display: inline-block;
}

.legend-item {
  margin: 0;
  &:last-child {
    border-right: none;
  }
}

.assignment-helper {
  height: $su-large;
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.results--pull-left,
.selected--pull-right {
  font-size: $tu-small-fontSize;
}

.PanelBody {
  padding: $su-medium;
  display: flex;
}
