// Input
//  -> Input component styles
//
// Input - default 100% width
// InputInline - modifier to allow label inline
//
// ============================================================

@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.InputIconContainer {
  composes: FieldInputIconContainer from '../Form/Field.module.scss';
}

.InputIcon {
  composes: FieldInputIcon from '../Form/Field.module.scss';
}

.Input {
  composes: FieldInput from '../Form/Field.module.scss';
  @media (max-width: $breakpoint-sm-max) {
    font-size: $tu-large-fontSize;
  }
}

.InputNote {
  display: block;
  margin: 0 0 $su-small;
}

.InputAligned {
  composes: FieldInputAligned from '../Form/Field.module.scss';
}

.InputError {
  composes: FieldInputError from '../Form/Field.module.scss';
  @media (max-width: $breakpoint-sm-max) {
    font-size: $tu-large-fontSize;
  }
}

.InputAlignedError {
  composes: FieldInputAlignedError from '../Form/Field.module.scss';
}
