@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.Panel {
  width: 600px;
}

.PanelBody {
  padding: $su-medium;
}

.PanelHeader {
  text-align: center;
}

.sentToText {
  color: $ts-green;
}

.viewEmailFooter {
  text-align: center;
  a,
  button {
    margin-left: $su-small;
  }
}
