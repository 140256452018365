@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.reactSelect {
  background-color: $cu-foreground;
  &[disabled] {
    background: transparent;
    opacity: 0.35;
    &:after {
      content: '🔒';
      color: $ts-grey;
    }
  }
  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    padding-right: 20px;
    text-indent: $su-xsmall;
    &:disabled {
      cursor: default;
    }
  }
}
