@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.Panel .Panel-row:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.Panel .Panel-cell {
  white-space: pre-line;
}

.Panel {
  margin-top: 16px;
}
