@import '~@teamsnap/teamsnap-ui/src/css/config/index';

// TODO : add this Tooltip modifier to TeamSnap-UI

.TooltipLeft:after {
  left: auto;
  right: 0;
  border-top-left-radius: $su-small;
  border-top-right-radius: 0;
}

// TODO : add to teamsnap UI as a list part of a list component

.CommaList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.CommaListItem {
  font-size: $tu-small-fontSize;
  color: $cu-info--light;
  display: inline;
  & + .CommaListItem:before {
    content: ', ';
  }
}

// TODO : add to Teamsnap-UI's Table component as modifier

.DataColumn {
  &:empty:after {
    content: '-';
  }
}

// TODO : reafactor with TeamSnap-UI utilities or add to tooltip CSS

.TruncateText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.PopupWidth {
  width: scaleGrid(24);
}

.SnapActionItem {
  cursor: pointer;
  & + .SnapActionItem {
    border-top: 1px solid $cu-divider;
  }
}

.rebuildPanel {
  border-left: 1px solid $cu-highlight;
  align-items: center;
  justify-content: center;
}
