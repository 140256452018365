@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.searchMembersContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: $su-medium;
}

.searchPillsContainer {
  margin-right: 8px;
}
