// InputControl
//  -> InputControl component styles
//
// InputControl - default - stacked controls
// InputControlInline - modifier to allow inline controls
//
// ============================================================

@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.InputControl,
.InputControlAligned {
  composes: Checkbox from '~@teamsnap/teamsnap-ui/src/css/components/Checkbox.scss';
  padding-bottom: 0;
  padding-left: 0;
}

.InputControlInline {
  composes: Checkbox Checkbox--inline from '~@teamsnap/teamsnap-ui/src/css/components/Checkbox.scss';
  padding: 0 $su-small;
}

.InputControlLabel {
  padding: none;
  composes: Checkbox-label from '~@teamsnap/teamsnap-ui/src/css/components/Checkbox.scss';
  display: inline-block;
  &:before {
    right: 0;
    display: inline-block;
    position: relative;
    margin-right: $su-xsmall;
  }
}

.InputControlInput {
  composes: Checkbox-input from '~@teamsnap/teamsnap-ui/src/css/components/Checkbox.scss';
}

.InputControlPadded {
  composes: InputControl;
  padding-top: $su-small;
  padding-left: 0;
}
