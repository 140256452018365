@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.SectionHeading {
  font-size: $tu-base-fontSize;
  font-weight: $tu-semibold-fontWeight;
  font-family: $tu-openSans-fontFamily;
  color: $cu-info;
  border-bottom: $border-width-medium solid $cu-divider--light;
  padding: 0 0 $su-xsmall 0;
  margin-bottom: $su-medium;
}

.SectionHeadingTitle {
  font-size: $tu-base-fontSize;
  font-weight: $tu-base-fontWeight;
  color: $cu-info;
  display: block;
  margin-bottom: 0;
}
