@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.panel-header {
  padding: 0;
}

.panel-body {
  height: 550px;
  overflow-y: scroll;
  border: none;
}

.sortable {
  cursor: pointer;

  &:hover {
    color: $color-links--hover;
  }
}

.table--full-width {
  border: none;
  margin: 0;
  border-collapse: collapse;
}

.col-player-select {
  position: relative;
}

.centerCheckBox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  margin: auto 0 auto -12px;
  height: 16px;
}

.col-player-name,
.col-player-gender,
.col-player-age,
.col-player-team,
.col-player-select {
  padding: 0 $su-small;
  float: left;
  height: scaleGrid(8);
  line-height: scaleGrid(8);
  font-weight: 600;
  display: flex;
}

.table-header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  margin: 0;
}

.col-player-name {
  width: 20%;
}

.col-player-gender {
  width: 14%;
}

.col-player-age {
  width: 12%;
}

.col-player-team {
  width: 27%;
}

.col-player-select {
  width: 7%;
}

.noMembers {
  padding: $su-xlarge $su-xlarge;
  border: none;
}

.noMembersIcon--ss-user {
  font-size: 70px;
  float: left;
  color: $cu-divider--light;
  line-height: 55px;
  padding-right: $su-small;
}

.noMembersText {
  margin-bottom: $su-xsmall;
}
