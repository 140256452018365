@import '~@teamsnap/teamsnap-ui/src/css/config/index';

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

// TODO: The Sub Panel and Filter title into components

.sub-title {
  margin-bottom: $su-xsmall;
}

.sub-panel {
  padding: $su-medium $su-medium;
  margin-bottom: $su-medium;
  border: 1px solid $cu-divider;
  background: $cu-middleground;
  border-radius: $border-radius-medium;
  &:after {
    content: '';
    display: block;
    clear: both;
  }
  hr {
    margin-bottom: $su-medium;
  }
  h5 {
    margin-bottom: $su-small;
  }
}

.Filter {
  padding-bottom: $su-medium;
  align-self: center;
  align-content: center;
  width: 100%;
  input:disabled {
    background: $cu-middleground !important;
    box-shadow: none !important;
  }
  &:after {
    content: '';
    display: block;
    clear: both;
  }
  &--NoLabel {
    padding: 20px 0 0;
  }
}

.FilterSpacer {
  content: ' ';
  background: #ccc;
  padding: 0 0.5px;
  margin: 0 1% 0 5px;
}

.RegistrationSelect {
  width: 30%;
  margin-right: 15px;
}

.BatchInvoiceSelect {
  width: 45%;
  margin-right: 15px;
}

.FilterGroup {
  @extend .Filter;
  padding-bottom: $su-small;
  display: flex;
  justify-content: space-between;
}

.FilterGroupItem {
  display: flex;
}

.FilterGroupRows {
  display: block;
}

.filter-title,
.footer-title {
  clear: both;
}

.QuickFilters {
  width: 100%;
  display: block;
}

.column-footer {
  width: 100%;
}

.small-button--success {
  margin-top: $su-small;
}

.input {
  float: left;
  width: 51%;
  padding-right: $su-small;
}

.button--ss-check--default {
  // width: 14%;
}

.checkbox--inline {
  display: flex;
  align-self: center;
}
