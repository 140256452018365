@import '~@teamsnap/teamsnap-ui/src/css/config/index';
@import '~@teamsnap/teamsnap-ui/src/css/components/Popup';

.searchPillContainer {
  display: inline-block;
  position: relative;

  margin-left: 10px;

  &:first-child {
    margin-left: 0px;
  }
}

.searchPill {
  background: $ts-white;
  border: 1px solid scaleColor($ts-grey, 4);
  border-radius: 30px;
  color: $ts-black;
  display: inline-block;
  height: scaleGrid(4);
  max-width: 200px;
  overflow: hidden;
  padding: 6px 12px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    background: scaleColor($ts-grey, 6);
    color: $ts-black;
  }
}

.searchPill.searchPill--active {
  background: $ts-darkblue;
  border: 0;
  color: scaleColor($ts-darkblue, 10);
  padding: 7px 13px;

  &:hover {
    background: scaleColor($ts-darkblue, -3);
  }
}

.Popup-title {
  border-bottom: 1px solid $ts-grey;
  color: $ts-darkblue;
  font-weight: bold;
  padding: $su-medium;
}

.Popup-footer {
  border-top: 1px solid $ts-grey;
  padding: $su-medium;
}
