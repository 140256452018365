.conditional-logic__container {
  select:has(option[value='']:checked),
  select:has(option[value='Month']:checked),
  select:has(option[value='Day']:checked),
  select:has(option[value='Year']:checked) {
    color: #98a4ae;
  }

  .Checkbox .Checkbox-input:checked + .Checkbox-label:before {
    background-color: #006ff5;
  }

  .Checkbox .Checkbox-input:not(:disabled):focus + .Checkbox-label:before,
  .Checkbox .Checkbox-input:not(:disabled) + .Checkbox-label:hover:before {
    border-color: #006ff5;
  }

  .Checkbox:last-child {
    padding-bottom: 0;
  }

  select {
    height: 32px;
    width: 100%;
    line-height: 32px;
    padding-right: 40px;
    padding-left: 8px;
    color: #383838;
    background-color: #fff;
    font-family: 'Open Sans', Helvetica, sans-serif;
    border-radius: 4px;
    border: 1px solid #d6d6d6;
    cursor: pointer;
    appearance: none;
  }

  .date-picker {
    > span {
      display: flex;
    }
  }

  .ts__select-box {
    position: relative;

    &:first-child {
      width: 43%;
    }

    &:nth-child(2) {
      width: 27%;
    }

    &:last-child {
      width: 30%;
    }

    &::after {
      font-family: SSPika;
      font-style: normal;
      font-weight: 400;
      text-decoration: none;
      text-rendering: optimizeLegibility;
      -ms-font-feature-settings: 'liga' 1;
      -o-font-feature-settings: 'liga';
      font-feature-settings: 'liga';
      -webkit-font-smoothing: antialiased;
      vertical-align: middle;
      content: '▾';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 32px;
      color: #afafaf;
      font-size: 10px;
      height: 24px;
      line-height: 28px;
      text-align: center;
      border-left: 1px solid #e6e6e6;
      pointer-events: none;
    }
  }

  .Button.u-borderNone {
    transition: none;
  }

  .Icon.u-fontSizeXs {
    font-size: 9px !important;
    position: relative;
    top: -1px;
    left: -1px;
  }

  .btn-add-condition {
    color: #006ff5;
    border: 0;
    padding: 0;
    height: auto;
    line-height: 1;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }
  }

  .btn-clear {
    border: 0;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }
  }

  .btn-save {
    color: #006ff5;
    border: 0;
    margin-left: 12px;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }
  }

  .btn-delete-condition {
    color: #e8301c;
    border: 0;
    padding: 0;
    height: auto;
    line-height: 1;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }
  }
}

.combobox-message {
  padding: 16px;
}

.multiselect-picker-container {
  position: relative;

  .multiselect-picker-selected {
    color: #383838;
    width: 100%;
    min-height: 32px;
    line-height: 30px;
    padding: 0 6px;
    box-sizing: border-box;
    box-shadow: none;
    background-color: #fff;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    font-family: inherit;
    font-size: 13px;
    transition: 0.2s;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;

    .selected {
      margin: 4px 2px;
      font-size: 14px;
      height: 20px;
      padding: 3px 8px;
      padding-right: 4px;
      background-color: #ebf4ff;
      line-height: 1;
      border-radius: 5px;
      display: inline-flex;
      align-items: center;

      span {
        line-height: 1.125;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 150px;
        overflow: hidden;
        white-space: nowrap;
      }

      button {
        margin: 0;
        padding: 0;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        position: relative;
        top: -1px;
      }

      .Icon {
        top: 0;
        margin-left: 4px;
      }
    }
  }

  .multiselect-picker-options {
    position: absolute;
    width: 100%;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    background-color: white;
    z-index: 10;
    box-shadow: 18.6024px 47.4863px 100px rgba(0, 0, 0, 0.07), 12.0571px 30.7782px 58.5648px rgba(0, 0, 0, 0.0531481),
      7.16535px 18.291px 31.8519px rgba(0, 0, 0, 0.0425185), 3.72047px 9.49727px 16.25px rgba(0, 0, 0, 0.035),
      1.51575px 3.86926px 8.14815px rgba(0, 0, 0, 0.0274815), 0.344488px 0.879377px 3.93519px rgba(0, 0, 0, 0.0168519);

    .options {
      padding: 19px;
      max-height: 300px;
      overflow-y: auto;
    }

    .actions {
      border-top: 1px solid #d1d1d1;
      padding: 8px 19px;
      display: flex;
      justify-content: flex-end;
    }
  }
}

option {
  width: 200px; // Adjustable
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
