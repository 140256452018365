@import '~@teamsnap/teamsnap-ui/src/css/config/index';

// TODO turn count into a component
// TODO clean up table styles so this TeamListRow class isn't needed
// TODO Chage $cu-highlight variable to callout
// TODO use clear mixin to clear Item Header
// TODO update font-weights to use variables

.teamListRow:first-child {
  .teamListItem {
    border-top: none;
  }
}

.teamListItem {
  padding: 0;
  border-top-color: $cu-divider--light;
}

.teamListItemHeader {
  padding: $su-small $su-small;
  font-size: $tu-base-fontSize;
  font-weight: 600;
  line-height: 22px;
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.indicator--ss-navigateright,
.indicator--ss-navigatedown {
  display: inline-block;
  font-size: 80%;
  padding-right: $su-xsmall;
}

.indicator--ss-navigatedown {
  color: $cu-highlight;
}

.countContainer {
  float: right;
}

.count {
  background: scaleColor($cu-divider, 6);
  padding: 0 $su-small;
  display: block;
  float: left;
  margin-left: $su-xsmall;
  height: $su-large;
  line-height: $su-large;
  border-radius: ($su-large / 2);
  font-size: $tu-small-fontSize;
  font-weight: $tu-base-fontWeight;
  i {
    display: inline-block;
    padding-left: 2px;
  }
}

.roster-list-loading {
  border-top: 1px dashed #eee;
  color: #999;
  padding: 10px 0;
  text-align: center;
}
