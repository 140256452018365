@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.breadcrumb {
  list-style: none;
  background: none;
  padding-left: 0;
  padding-bottom: 0;
  min-height: $su-large;
  &:after {
    content: '';
    clear: both;
    display: block;
  }
  li {
    float: left;
    padding-bottom: 0;
    &::before {
      content: '/';
      padding: 0 $su-xsmall;
      color: $cu-divider;
    }
    &:first-child {
      &::before {
        display: none;
      }
    }
  }
}

.rootName {
  color: scaleColor($cu-info, 6);
  font-size: $tu-small-fontSize;
  padding-left: $su-xxsmall;
  &:before {
    display: none;
  }
}
