// TableHeader
//  -> Composes table header styles for table component
//
//
// ============================================================

@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.Icons {
  pointer-events: none;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  padding-left: $su-xsmall;
}

.IconTop {
  margin-top: -$su-small;
  position: absolute;
  text-align: center;
  font-size: $tu-xsmall-fontSize;
  color: $cu-divider;
}

.IconBottom {
  position: absolute;
  margin-bottom: -$su-small;
  text-align: center;
  font-size: $tu-xsmall-fontSize;
  color: $cu-divider;
}

.IconTopActive {
  composes: IconTop;
  color: #ff8f00;
}

.IconBottomActive {
  composes: IconBottom;
  color: #ff8f00;
}
