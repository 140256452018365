@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.InputGroupSelect {
  position: relative;
  height: ($su-xlarge);
  line-height: ($su-xlarge - 2px);
  border: 1px solid $cu-divider;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  padding: 0 $su-medium;
  background-color: $cu-foreground;
}

.InputGroupSelectLabel {
  color: $color-links;
  &:after {
    content: '▾';
    @include ss-pika;
    font-size: 7px;
    display: inline-block;
    padding-left: 4px;
  }
}

.InputGroupSelectOptions {
  opacity: 0;
  appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: $cu-info;
}

.InputGroupSelectInput {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  min-width: scaleGrid(35);
}
