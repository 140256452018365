@import '~@teamsnap/teamsnap-ui/src/css/config/index';

// TODO: Make memberName with role icons a component

.col-player-name,
.col-player-gender,
.col-player-age,
.col-player-team,
.col-player-select {
  padding: $su-small;
  font-size: $tu-small-fontSize;
}

.col-player-name {
  width: 20%;
}

.col-player-gender {
  width: 14%;
}

.col-player-age {
  width: 12%;
}

.col-player-team {
  width: 27%;
}

.col-player-select {
  width: 7%;
}

.rosterListItem {
  // can't use "-row" in a css class name without it being compiled incorrectly
  cursor: pointer;
  &:hover td {
    // background: #eee;
  }
  &.selected td {
    background: $cu-middleground;
  }
  td {
    border-top-color: $cu-divider--light;
  }
}

.memberName {
  display: inline-block;
  padding-right: $su-xsmall;
}

.divisionName {
  display: block;
  font-size: $tu-xsmall-fontSize;
  color: $cu-info--light;
}

.pendingLabel {
  display: block;
  font-size: $tu-xsmall-fontSize;
  color: $cu-info--light;
}

.pendingMove--toTeam {
  td {
    background-color: rgba(127, 194, 67, 0.05) !important;
  }
}

.pendingMove--fromTeam {
  td {
    background-color: #f4f4f4 !important;
  }
}
