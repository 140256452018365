@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.filter-dismissal--ss-delete {
  font-size: 85%;
  &:before {
    padding: 0;
    margin-left: $su-small;
  }
}

.filter-title {
  border-bottom: 1px solid $cu-divider--light;
}

.advanced-filter {
  padding: $su-small 0;
  display: flex;
  width: 100%;
  &:before {
    content: '&';
    // line-height: $form-select-line-height;
    padding-right: $su-xsmall;
    font-size: $tu-small-fontSize;
    color: $cu-info--light;
  }
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
  }
}

.filter-type {
  width: 50%;
}

.filter-name {
  width: 56%;
  margin-right: 2%;
}

.filter-qualifier {
  margin: 0;
  width: 40%;
}

.filter-content {
  width: 35%;
  input[type='text'] {
    width: 100%;
  }
  label {
    width: 100%;
  }
}

.filter-dismissal {
  width: 30px;
  padding-left: $su-small;
  padding-top: $su-xsmall;
}

.InputSelect {
  width: 100%;
}
