@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.panel-title--pull-left {
  // line-height: $button-height;
  font-weight: 600;
}

.panel-body {
  border: none;
  height: 550px;
  overflow-y: scroll;
  border-top: none;
}

.flex {
  display: flex;
  padding-left: $su-small;
  padding-right: $su-small;
  align-items: center;
}

.table--full-width {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $cu-divider--light;
}

.noTeams {
  padding: $su-xlarge $su-xlarge;
  border: none;
}

.noTeamsIcon--ss-users {
  font-size: 70px;
  float: left;
  color: $cu-divider--light;
  line-height: 55px;
  padding-right: $su-small;
}

.noTeamsText {
  margin-bottom: $su-xsmall;
}

.teamListHeader {
  font-weight: $tu-semibold-fontWeight;
  // line-height: $button-height;
  flex: 1 1 0%;
}
