@import '~@teamsnap/teamsnap-ui/src/css/config/index';

// TODO: add pill button to the framework CSS

.filter-dismissal--ss-delete {
  font-size: 85%;
  &:before {
    padding: 0;
    margin-left: $su-small;
  }
}

.pill-button {
  height: 30px;
  line-height: 28px;
  border-radius: 15px;
  border-bottom-width: 1px;
  padding-left: $su-medium;
  padding-right: $su-small;
  background-color: $cu-foreground;
  margin: 0 $su-xsmall $su-small 0;
  font-size: $tu-small-fontSize;
  & + .pill-button {
    margin-left: 0;
  }
}

.active-filter {
  color: #fff;
  background-color: #2a4b65;
}
