// Button
//  -> Composes base buttons and modifiers from TeamSnapUI
//
// Base Styles: Button, ButtonPrimary, ButtonNegative
// Modifiers: Small, Large, IsActive, IsDisabled
//
// ============================================================

/* ------------------------------------------------------------
 * Button (default) Styles
 * ------------------------------------------------------------ */

.Button {
  composes: Button from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonSmall {
  composes: Button Button--small from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonXSmall {
  composes: ButtonSmall;
  padding: 0 6px;
}

.ButtonLarge {
  composes: Button Button--large from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonIsActive {
  composes: Button is-active from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonIsDisabled {
  composes: Button is-disabled from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonSmallIsActive {
  composes: Button Button--small is-active from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonSmallIsDisabled {
  composes: Button Button--small is-disabled from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonXSmallIsDisabled {
  composes: ButtonXSmall;
  composes: is-disabled from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonLargeIsActive {
  composes: Button Button--large is-active from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonLargeIsDisabled {
  composes: Button Button--large is-disabled from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

/* ------------------------------------------------------------
 * ButtonPrimary Styles
 * ------------------------------------------------------------ */
.ButtonPrimary {
  composes: Button Button--primary from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonPrimaryIsActive {
  composes: Button Button--primary is-active from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonPrimaryIsDisabled {
  composes: Button Button--primary is-disabled from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonPrimarySmall {
  composes: Button Button--primary Button--small from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonPrimaryXSmall {
  composes: ButtonPrimarySmall;
  padding: 0 6px;
}

.ButtonPrimaryLarge {
  composes: Button Button--primary Button--large from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonPrimarySmallIsActive {
  composes: Button Button--primary Button--small is-active from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonPrimarySmallIsDisabled {
  composes: Button Button--primary Button--small is-disabled from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonPrimaryLargeIsActive {
  composes: Button Button--primary Button--large is-active from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonPrimaryLargeIsDisabled {
  composes: Button Button--primary Button--large is-disabled from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

/* ------------------------------------------------------------
 * ButtonNegative Styles
 * ------------------------------------------------------------ */
.ButtonNegative {
  composes: Button Button--negative from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonNegativeIsActive {
  composes: Button Button--negative is-active from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonNegativeIsDisabled {
  composes: Button Button--negative is-disabled from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonNegativeSmall {
  composes: Button Button--negative Button--small from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonNegativeXSmall {
  composes: ButtonNegativeSmall;
  padding: 0 6px;
}

.ButtonNegativeLarge {
  composes: Button Button--negative Button--large from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonNegativeSmallIsActive {
  composes: Button Button--negative Button--small is-active from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonNegativeSmallIsDisabled {
  composes: Button Button--negative Button--small is-disabled from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonNegativeLargeIsActive {
  composes: Button Button--negative Button--large is-active from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}

.ButtonNegativeLargeIsDisabled {
  composes: Button Button--negative Button--large is-disabled from '~@teamsnap/teamsnap-ui/src/css/components/Button.scss';
}
