@import '~@teamsnap/teamsnap-ui/src/css/config/index';

// TODO: Make memberName with role icons a component

.col-gender,
.col-age {
  text-align: center;
}

.col-team {
  text-align: left;
}

.drag-row {
  margin-top: 10px;

  &.is-dragging {
    opacity: 0.5;
  }
}

.disabled td {
  color: lighten($cu-info, 30%);
}

.memberName {
  display: inline-block;
  padding-right: $su-xsmall;
}
