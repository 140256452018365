@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.Panel {
  width: 100%;
  margin: 0 auto;
}

.PanelBody {
  padding: $su-medium;
}

.clubEmailFooter {
  text-align: center;
  a,
  button {
    margin-left: $su-small;
  }
}

.clubEmailEditor {
  div {
    box-shadow: none;
  }
}

.errorBanner {
  background-color: #fbddda;
  color: #bf2313;
  padding: 12px 16px;
  border-radius: 5px;

  h4 {
    color: #bf2313 !important;
    font-size: 22px;
    font-weight: 700;
  }
}
