.combobox-container {
  position: relative;

  .Checkbox .Checkbox-input:checked+.Checkbox-label:before {
    background-color: #006ff5;
  }

  .Checkbox .Checkbox-input:not(:disabled):focus+.Checkbox-label:before,
  .Checkbox .Checkbox-input:not(:disabled)+.Checkbox-label:hover:before {
    border-color: #006ff5;
  }

  .Checkbox:last-child {
    padding-bottom: 0;
  }

  .btn-clear {
    border: 0;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }
  }

  .btn-save {
    color: #006ff5;
    border: 0;
    margin-left: 12px;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }
  }

  .combobox-options {
    width: 100%;
    background-color: white;

    .options {
      padding: 19px;
      max-height: 240px;
      overflow-y: auto;
    }
  }

  .combobox-message {
    padding: 16px;
  }

  .combobox-options__search-container {
    padding: 16px;
  }

  .combobox-options__search {
    display: flex;
    align-items: center;
    border: 1px solid #d6d6d6;
    border-radius: 5px;

    .Icon {
      margin-left: 8px;
      font-size: 14px;
      fill: #999999;
    }

    .FieldGroup {
      margin-bottom: 0;
      flex: 1;

      input {
        border: 0;
      }
    }
  }
}
