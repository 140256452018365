@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.drop-target-container {
  width: 100%;
  position: relative;

  .drop-target {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    background-color: rgba($cu-background, 0.75);

    .drop-target-box {
      width: calc(100% - 25px);
      height: calc(100% - 25px);
      border: 2px dashed rgba($cu-primary, 0.35);
      border-radius: 5px;
      margin: 12px auto;
      text-align: center;

      span {
        font-size: 20px;
        margin-top: 40%;
        display: inline-block;
        color: $cu-info;
      }
    }
  }
}
