@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.Paginate,
.PaginateRight {
  display: inline-flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.PaginateCounter {
  margin-right: $su-small;
  font-weight: $tu-base-fontWeight;
  color: $cu-info;
}

.PaginateItem {
  background-color: $cu-foreground;
  border-right: 1px solid $cu-divider--light;
  border: 1px solid $cu-divider;
  border-bottom-width: 2px;
  height: scaleGrid(4);
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  & + .PaginateItem {
    border-left: none;
  }

  &:first-child {
    border-top-left-radius: $border-radius-medium;
    border-bottom-left-radius: $border-radius-medium;
  }

  &:last-child {
    border-top-right-radius: $border-radius-medium;
    border-bottom-right-radius: $border-radius-medium;
    border-right: 1px solid $cu-divider;
  }

  &:hover {
    background: scaleColor($cu-foreground, -1);
  }

  a {
    font-size: $tu-base-fontSize;
    color: $cu-info;
    height: 100%;
    line-height: scaleGrid(4) - 3;
    padding: 0 scaleGrid(2);
    display: block;
    font-weight: 600;
  }
}

.PaginateItemIsActive {
  composes: PaginateItem;
  background: scaleColor($cu-foreground, -1);
  border-top-width: 2px;
  border-bottom-width: 1px;
}

.PaginateItemIsDisabled {
  composes: PaginateItem;
  pointer-events: none;
  border-width: 1px !important;
  background: $cu-background;

  a {
    color: $cu-info--light !important;
  }
}
