// ButtonGroup
//  -> Current styles are copied from TeamsnapUI instead of composed, this is temporary.
//     There are some fairly large changes/issues with the TeamsnapUI
//     pacakge and it's causing local issues.
//
//  * This will require a large fix in TeamsnapUI as well as changes for each project.
//
// ============================================================

@import '~@teamsnap/teamsnap-ui/src/css/config/index';

$ButtonGroup-border-radius: $border-radius-medium;
$ButtonGroup-space: -1 * $border-width-small;

.ButtonGroup {
  display: flex;
  display: -ms-flexbox;
}

.ButtonGroup > button {
  margin: 0 $ButtonGroup-space;
  border-radius: 0;
}

.ButtonGroup > button:first-child {
  border-top-left-radius: $ButtonGroup-border-radius;
  border-bottom-left-radius: $ButtonGroup-border-radius;
}

.ButtonGroup > button:last-child {
  border-top-right-radius: $ButtonGroup-border-radius;
  border-bottom-right-radius: $ButtonGroup-border-radius;
}
