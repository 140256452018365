@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.drop-zone {
  position: relative;
  &:after {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $cu-background;
    opacity: 0.35;
  }
  &.active {
    min-height: 160px;
    .dropZone__table {
      position: relative;
      &:after {
        opacity: 0.75;
        content: '';
        background: $cu-middleground;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
      }
    }
    .drop-info {
      display: block;
      z-index: 100;
    }
    &:after {
      display: block;
    }
  }
}

.dropZone__table {
  border-radius: 0;
  border: none;
  margin-bottom: 0;
  width: 100%;
  border-top: 1px solid $cu-divider--light;
  td,
  th {
    background: $cu-middleground;
    border-radius: 0 !important;
    padding: $su-small $su-small;
    font-size: $tu-small-fontSize;
  }
  th {
    font-weight: 600;
    text-align: left;
    cursor: pointer;
    border-top: 0;
    &:hover {
      color: #0279ce;
    }
  }
  tfoot td {
    padding: 15px;
    text-align: center;
  }

  &.dropZone__table__hasPendingAssignments {
    border-top: 1px solid $cu-highlight;
  }
}

.drop-info {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: calc(100% - 25px);
  height: calc(100% - 25px);
  border: 1px dashed $cu-primary;
  border-radius: 5px;
  .pre-drop,
  .post-drop {
    width: 315px;
    height: 85px;
    position: absolute;
    top: 24px;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.drop-placeholder {
  padding: $su-large;
  display: block;
  text-align: center;
}

.pendingAssignmentsRow {
  background: $cu-middleground;
  border-top: 1px solid $cu-highlight;
}
