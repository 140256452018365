@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.PopupWidth {
  width: scaleGrid(24);
  right: 0 - $su-medium;
}

// TODO : get rid of this duplication
.SnapActionItem {
  cursor: pointer;
  text-align: left;
  &:hover {
    color: $color-links--hover;
  }
  & + .SnapActionItem {
    border-top: 1px solid $cu-divider;
  }
}
