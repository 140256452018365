@import '~@teamsnap/teamsnap-ui/src/css/config/index';

// TODO turn modal into a TeamSnap-UI component for consistency

.ModalContainer {
  max-width: scaleGrid(50);
  width: 100%;
  max-height: 100vh; // 1
  z-index: 10;
  position: relative;
}

.ModalHeader,
.ModalBody,
.ModalFooter {
  padding: $su-medium;
}

.ModalHeader {
  text-align: center;
  border-bottom: 1px solid $cu-divider;
}

.ModalFooter {
  text-align: right;
  border-top: 1px solid $cu-divider;
}
