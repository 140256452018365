@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.PreDrop,
.PreDropText {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.PreDrop {
  height: 100%;
  max-height: scaleGrid(30);
  opacity: 0.75;
  cursor: pointer;
  transition: opacity, 250ms, ease-in-out;
  &:hover {
    opacity: 1;
  }
}

.PreDropText {
  text-align: center;
  height: $su-large;
  cursor: pointer;
  font-size: $tu-large-fontSize;
  color: $cu-primary;
  right: 0;
  bottom: 0;
  margin: auto;
}

.ClickAssignText {
  display: block;
}

.ClickAssignText:hover {
  color: $color-links;
}

.OverDrop {
  opacity: 1;
}
