@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.PostDrop {
  width: 260px;
  height: 65px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding-left: 70px;
}

.PostDrop-icon,
.PostDrop-title {
  color: $cu-positive;
}

.PostDrop-icon {
  font-size: 70px;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 50px;
}

.PostDrop-title {
  font-size: $tu-large-fontSize;
  color: $cu-positive;
  display: block;
}

.PostDrop-text {
}
