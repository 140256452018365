@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.Heading {
  margin: 0;
  font-weight: $tu-semibold-fontWeight;
  padding-bottom: $su-small;
}

.HeadingSpacerLeft {
  margin-left: $su-small;
}

.HeadingSpacerRight {
  margin-right: $su-small;
}

.HeadingSpacer {
  composes: HeadingSpacerLeft;
  composes: HeadingSpacerRight;
}
