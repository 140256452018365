@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.loading-screen {
  width: 400px;
  margin: 40px auto;
}
.loading-indicator {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border-top: solid 2px #444;
  border-right: solid 2px transparent;
  box-shadow: 0 0 0 1px #ccc;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin: 4px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.loading-text {
  font-size: 18px;
  font-weight: bold;
  color: #666;
  margin: 4px;
  display: inline-block;
  vertical-align: middle;
}
.startup-message {
  margin-top: $su-medium;
  font-size: 16px;
  color: #999;
}
span.loading-indicator {
  display: inline-block;
  vertical-align: middle;
}
.loading-indicator.small {
  width: 12px;
  height: 12px;
  margin: 2px;
}
