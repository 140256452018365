@import '~@teamsnap/teamsnap-ui/src/css/config/index';

// TODO: Turn this sub panel into a component

.sub-title {
  margin-bottom: $su-xsmall;
}

.sub-panel {
  padding: $su-medium $su-medium;
  margin-bottom: $su-medium;
  border: 1px solid $cu-divider;
  background: $cu-middleground;
  border-radius: $border-radius-medium;
  display: flex;
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}
