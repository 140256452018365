@import '~@teamsnap/teamsnap-ui/src/css/config/index';

//------------------------------------------------------------//

// * Getting Started Widget
// * 1. SideNav Styles
// * 2. Content Styles

// Notes:

//------------------------------------------------------------//

// * 1. SideNav Styles

.chartGraph {
  height: scaleGrid(15);
  overflow: hidden;
  box-sizing: content-box;
  padding-bottom: scaleGrid(4);
}

.chartColumn {
  position: relative;
  padding: 0 (0.5 * $su-small);
  flex: 1 1 0% !important; // 1
  flex-basis: 0% !important; // 2
}

.chartYaxis {
  display: flex;
  flex-direction: column;
  height: calc(100% + #{$su-medium});
  margin-top: (0 - ($su-medium / 2));
  padding-right: $su-xsmall;
}

.chartYaxisLabel {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-right: $su-xxsmall;
  text-align: right;
  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 3000%;
    height: 1px;
    border-top: 1px dashed $cu-divider;
  }
}

.chartXaxisLabel {
  line-height: 1;
  padding-top: $su-xsmall;
  color: $cu-info--light;
  text-align: center;
  width: 100%;
  height: scaleGrid(4);
  position: absolute;
  top: 100%;
  left: 0;
  span {
    display: block;
  }
}
