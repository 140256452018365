@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $su-small;
}

.ButtonGroup {
  composes: ButtonGroup from '../../../../shared/toolkit/ButtonGroup/ButtonGroup.module.scss';
  margin-left: auto;
  margin-right: $su-small;
}
