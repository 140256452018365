:global {
  .app-message-enter {
    opacity: 0.01;
  }

  .app-message-enter.app-message-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  .app-message-leave {
    opacity: 1;
  }

  .app-message-leave.app-message-leave-active {
    opacity: 0.01;
    transition: opacity 100ms ease-in;
  }

  .app-message-appear {
    opacity: 0.01;
  }

  .app-message-appear.app-message-appear-active {
    opacity: 1;
    transition: opacity 100ms ease-in;
  }
}
