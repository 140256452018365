@import '~@teamsnap/teamsnap-ui/src/css/config/index';

.Footer {
  display: flex;
  justify-content: space-between;
}

.FooterActions {
  button:first-child {
    margin-right: $su-small;
  }
}
